<template>
  <div class="d-flex flex-column flex-root bg-light">
    <!-- begin::Body -->
    <div class="d-flex flex-row page">
      <div class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <nav id="header" :class="{ 'header-fixed': !isHeaderHidden }"
          class="navbar navbar-expand-lg navbar-light bg-white" style="z-index: 999;">
          <router-link to="/" style="text-decoration: none">
            <div id="logo">
              <img src="https://dicopt.com/wp-content/uploads/2021/02/logo.png" alt="Dicopt" title="Dicopt">
            </div>
          </router-link>

          <div class="register">
            <ul class="mb-0">
              <template v-if="('paid_register' in firestoreUser.userData && firestoreUser.userData['paid_register'])">
                <li id="liMenu">
                  <router-link to="/suscripciones" style="text-decoration: none">
                    <span id="dashboard">Suscripciones</span>
                  </router-link>
                </li>
                <li id="liMenu">
                  <router-link to="/pacientes" style="text-decoration: none">
                    <span id="dashboard">Pacientes</span>
                  </router-link>
                </li>
              </template>
              <li id="liMenu2">
                <b-dropdown id="test-dropdown" no-caret variant="text">
                  <template #button-content>
                    <div id="testto">
                      Documentación
                      <inline-svg src="media/svg/icons/Navigation/Angle-down.svg" />
                    </div>
                  </template>
                  <b-dropdown-item @click="navigateToOption('/home')" class="w-100">
                    Bienvenida
                  </b-dropdown-item>
                  <b-dropdown-item @click="navigateToOption('/guia-especialistas')" class="w-100">
                    Guía para especialistas
                  </b-dropdown-item>
                  <b-dropdown-item @click="navigateToOption('/juegos')" class="w-100">
                    Juegos
                  </b-dropdown-item>
                  <b-dropdown-item @click="navigateToOption('/plataforma')" class="w-100">
                    Plataforma
                  </b-dropdown-item>
                  <b-dropdown-item @click="navigateToOption('/graficas')" class="w-100">
                    Gráficas
                  </b-dropdown-item>
                  <b-dropdown-item @click="navigateToOption('/extra-doc')" class="w-100">
                    Ejercicios extra
                  </b-dropdown-item>
                  <b-dropdown-item @click="navigateToOption('/faq-plataforma')" class="w-100">
                    Preguntas frecuentes
                  </b-dropdown-item>
                </b-dropdown>
              </li>
              <!--
              <li id="liMenu">
                <span id="dashboard" @click="redirigir()">Suscripciones</span>
              </li>
              -->
            </ul>
          </div>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item dropdown"></li>
            </ul>
          </div>

          <!-- Los botones cambian en función de si el usuario esta logueado o no -->
          <div class="register">
            <ul>
              <a href="#">
                <li @click.prevent="logout" id="helper">Cerrar Sesión</li>
              </a>
            </ul>
          </div>

        </nav>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div :style="{ marginTop: isHeaderHidden ? '0' : '100px' }">
          <router-view />
        </div>
        <!-- end:: Content -->
      </div>
    </div>
    <!-- begin::Body -->
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "DashboardVista",
  data() {
    return {
      prevScrollPos: window.pageYOffset,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions(['fetchUserData']),
    logout() {
      this.$store.dispatch("firebase/logOut")
      this.$router.push('/login')
    },
    buttonAction: function () {
      var win = window.open(
        "https://dicopt.com/producto/dicopt-standard/",
        "_blank"
      );
      win.focus();
    },
    navigateToOption(selectedOption) {
      if (this.$router.currentRoute.path !== selectedOption) {
        this.$router.push(selectedOption);
      }
    },
    handleScroll() {
      const currentScrollPos = window.pageYOffset;
      const isHeaderHidden = currentScrollPos > this.prevScrollPos;
      this.prevScrollPos = currentScrollPos;
      this.$store.dispatch('updateHeaderState', isHeaderHidden);
    },
    redirigir() {
      window.location.href = "https://avantsalud.dicopt.com";
    }
  },
  computed: {
    ...mapState(["firestoreUser"]),
    isHeaderHidden() {
      return this.$store.state.isHeaderHidden;
    }
  },
  created() {
    this.fetchUserData();
  }
}
</script>

<style scoped>
#logo {
  width: 150px;
  overflow: hidden;
  margin-left: 20px;
}

img {
  width: 100%;
}

.nav-item {
  font-size: 18px;
}

.register {
  display: flex;
  flex-direction: row
}

#dashboard {
  font-size: 18px;
  color: rgba(15, 41, 114, 0.75);
  position: relative;
}

#helper {
  display: inline-block;
  background: none;
  line-height: 40px;
  list-style: none;
  margin-right: 30px;
  font-size: 18px;
  border-radius: 5px;
  height: 40px;
  width: 140px;
  text-align: center;
  color: white;
  font-weight: 600;
  background-color: rgba(12, 21, 114, 0.56);
  position: relative;
  bottom: 10px;
}

#liMenu {
  display: inline-block;
  background: none;
  line-height: 40px;
  list-style: none;
  margin-right: 30px;
  font-size: 18px;
  border-radius: 5px;
  height: 40px;
  width: 140px;
  text-align: center;
  color: white;
  font-weight: 600;
}

#liMenu2 {
  display: inline-block;
  height: 40px;
}

.container-div {
  display: flex;
  align-items: center;
}

#testto {
  font-size: 18px;
  color: rgba(15, 41, 114, 0.75);
  font-weight: 600;
}

.header-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  transition: top 0.3s ease-in-out;
}
</style>
