import app from "@/firebaseConfig";
import { getFirestore, collection, onSnapshot, updateDoc, doc, getDoc } from "firebase/firestore";
var db = getFirestore(app);

import firebase from './firebase.js'

import { getAuth } from "firebase/auth";
const auth = getAuth();

const state = {
  userData: {},
  patientsData: [],
  licensesInUse: [],
  licensesFree: [],
  licensesDisable: [],
  licensesSpecial: [],
  patientIndex: 0,
  patientStatistics: {}
}

const getters = {
  getUserData: (state) => state.userData,
  getPatientsData: (state) => state.patientsData,
  getLicensesInUseData: (state) => state.licensesInUse,
  getLicensesFreeData: (state) => state.licensesFree,
  getLicensesDisableData: (state) => state.licensesDisable,
  getLicensesSpecialData: (state) => state.licensesSpecial,
  getPatientIndex: (state) => state.patientIndex,
  getPatientStatistics: (state) => state.patientStatistics,
}

const mutations = {
  setUserData: (state, value) => state.userData = value,
  setPatientsData: (state, value) => state.patientsData = value,
  setLicensesInUseData: (state, value) => { state.licensesInUse = value; },
  setLicensesFreeData: (state, value) => state.licensesFree = value,
  setLicensesDisableData: (state, value) => state.licensesDisable = value,
  setLicensesSpecialData: (state, value) => state.licensesSpecial = value,
  setPatientIndex: (state, value) => state.patientIndex = value,
  setPatientStatistics: (state, value) => { state.patientStatistics = { ...value }; }
}

const actions = {
  fetchUserData: async function ({ commit }) {
    const docRef = doc(db, "usuarios", firebase.state.user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      commit("setUserData", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  },
  fetchPatientsData: async function ({ commit }) {
    onSnapshot(collection(db, "usuarios", firebase.state.user.uid, "pacientes"), querySnapshot => {
      const data = [];
      querySnapshot.forEach((doc) => {
        const item = {};
        const aux_data = doc.data();
        item.uid = doc.id;
        if ('alta' in aux_data) {
          item.alta = aux_data.alta.toDate();
        }
        if ('archived' in aux_data) {
          item.archived = aux_data.archived;
        }
        if ('cancel_pending' in aux_data) {
          item.cancel_pending = aux_data.cancel_pending;
        }
        if ('contrast_user' in aux_data) {
          item.contrast_user = aux_data.contrast_user;
        }
        if ('email' in aux_data) {
          item.email = aux_data.email;
        }
        if ('vergence_user' in aux_data) {
          item.vergence_user = aux_data.vergence_user;
        }
        if ('licencia' in aux_data) {
          item.licencia = aux_data.licencia;
        }
        if ('next_pay' in aux_data) {
          if (aux_data.next_pay == null || aux_data.next_pay == '') {
            item.next_pay = null;
          }
          else{
            item.next_pay = aux_data.next_pay.toDate();
          }
        }
        if ('ref_code' in aux_data) {
          item.ref_code = aux_data.ref_code;
        }
        if ('treat_config' in aux_data) {
          item.treat_config = aux_data.treat_config;
        }
        if ('trial_period' in aux_data) {
          item.trial_period = aux_data.trial_period;
        }
        data.push(item);
      });
      commit("setPatientsData", data);
    });
  },

  fetchLicensesData: async function ({ commit }) {
    onSnapshot(collection(db, "usuarios", firebase.state.user.uid, "licencias"), querySnapshot => {
      const data = [];
      querySnapshot.forEach((doc) => {
        const item = {};
        const aux_data = doc.data();
        item.uid = doc.id;
        if ('id_licencia' in aux_data) {
          item.id_licencia = aux_data.id_licencia;
        }
        if ('is_active' in aux_data) {
          item.is_active = aux_data.is_active;
        }
        if ('renovado' in aux_data) {
          item.renovado = aux_data.renovado.toDate();
        }
        if ('tipo' in aux_data) {
          item.tipo = aux_data.tipo;
        }
        if ('woocommerce' in aux_data) {
          item.woocommerce = aux_data.woocommerce;
        }
        if ('patient_uid' in aux_data) {
          item.patient_uid = aux_data.patient_uid;
        }
        data.push(item);
      });
      const aux_licensesDisable = data.filter(item => !('is_active' in item) || !item.is_active);
      commit("setLicensesDisableData", aux_licensesDisable);
      const aux_licensesFree = data.filter(item => (('is_active' in item) && item.is_active) && (!('patient_uid' in item) || item.patient_uid == null || item.patient_uid.trim() === '') && ('tipo' in item && item.tipo === 1));
      commit("setLicensesFreeData", aux_licensesFree);
      const aux_licensesInUse = data.filter(item => (('is_active' in item) && item.is_active) && ('patient_uid' in item && item.patient_uid != null && item.patient_uid.trim() !== '') && ('tipo' in item && item.tipo === 1));
      commit("setLicensesInUseData", aux_licensesInUse);
      const aux_licensesSpecial = data.filter(item => (('is_active' in item) && item.is_active) && ('tipo' in item && item.tipo !== 1));
      commit("setLicensesSpecialData", aux_licensesSpecial);
    });
  },

  activeLicense2Patient: async function ({ dispatch }) {
    const dataDict = { subscribe_user: true };
    await dispatch("updateRealtimeData", [dataDict, false]);
  },

  removeLicense2Patient: async function ({ dispatch }, immediate_cancel = false) {
    const dataDict = { subscribe_user: false };
    dispatch("updateRealtimeData", [dataDict, immediate_cancel]);
  },

  updateFirestoreData({ rootState }, payload) {
    // Actualizar los datos en Firestore utilizando la API de Firestore
    const data = {};
    data[payload.aux_variable] = payload.value;

    updateDoc(doc(db, "usuarios", rootState.firebase.user.uid, payload.collection, payload.collection_id), data)
      .then(() => {
        // Manejar el resultado exitoso aquí
        console.log('Los datos se actualizaron correctamente');
      })
      .catch((error) => {
        // Manejar el error aquí
        console.error('Ocurrió un error al actualizar los datos:', error);
      });
  },

  updateRealtimeData({ state, rootState }, dataDict_cancelData) {
    auth.currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      // Send token to your backend via HTTPS
      // ...
      var data = {
        uid: state.patientsData[state.patientIndex].uid,
        data: dataDict_cancelData[0],
        immediate_cancel: dataDict_cancelData[1]
      };

      if ("coupon_in_reactivation" in state.userData && state.userData.coupon_in_reactivation && "coupon" in state.userData ) {
        data.coupon = state.userData.coupon;
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + idToken },
        body: JSON.stringify(data)
      };
      fetch(`${rootState.apiUrl}/updatePatientData`, requestOptions)
        .then(response => response.json());

    }).catch(function (error) {
      // Handle error
      console.log(error)
    });
  },

  fetchPatientStatistics({ state, commit }, dates) {
    return new Promise((resolve, reject) => {
      // Read statistics from Firestore 
      const querySnapshot = collection(db, "usuarios", firebase.state.user.uid, "pacientes", state.patientsData[state.patientIndex].uid, "estadisticas");
      const data = {};
      // generate a list of strings with the dates in the range between dates[0] and dates[1] in format YYYYMMDD
      const date_list = [];
      const start_date = new Date(dates[0]);
      const end_date = new Date(dates[1]);
      const aux_date = new Date(start_date);
      while (aux_date <= end_date) {
        const year = aux_date.getFullYear();
        const month = String(aux_date.getMonth() + 1).padStart(2, "0");
        const day = String(aux_date.getDate()).padStart(2, "0");
        date_list.push(`${year}${month}${day}`);
        aux_date.setDate(aux_date.getDate() + 1);
      }

      // Crear una matriz de promesas para cada llamada a getDoc
      const promises = date_list.map((date) => {
        const estadisticasDoc = doc(querySnapshot, date);
        return getDoc(estadisticasDoc)
          .then((snapshot) => {
            if (snapshot.exists()) {
              data[date] = snapshot.data();
            } else {
              data[date] = null;
            }
          })
          .catch((error) => {
            console.log('Error al acceder al documento:', error);
          });
      });

      // Esperar a que se resuelvan todas las promesas antes de llamar al commit
      Promise.all(promises)
        .then(() => {
          commit("setPatientStatistics", data);
          resolve(); // Resolvemos la promesa para indicar que la acción está completa
        })
        .catch((error) => {
          reject(error); // Rechazamos la promesa en caso de error
        });
    });
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
