<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
    >

      <!--begin::Aside
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #006D86;"
      >
        <video-bg
          :sources="[
            'https://dicopt.com/wp-content/uploads/2021/04/Mariquita.mp4'
          ]"
        >
        </video-bg>
      </div>
      end::Aside-->

      <!--begin::Content-->
      <div
        class="flex-row-fluid d-flex flex-column justify-content-center"
      >
        <div class="d-flex flex-center">
          <!--begin::Signin-->
            <form class="mx-1 mx-md-4" @submit.prevent="login">
              <div class="pb-13 pt-lg-0 pt-5" style="text-align:center">
                <img
                  class="max-h-70px"
                  src="media/logos/dicopt-logo.png"
                  alt=""
                />
              </div>

              <div class="d-flex flex-row align-items-center mb-4">
                <div class="form-outline flex-fill mb-0">
                  <label class="font-size-h6 font-weight-bolder text-dark" for="form3Example3c">Email</label>
                  <input
                    v-model="email"
                    type="email"
                    required id="form3Example3c"
                    class="form-control form-control-solid h-auto py-6 px-6 rounded-lg"/>

                </div>
              </div>

              <div class="d-flex flex-row align-items-center mb-4">
                <div class="form-outline flex-fill mb-0">
                  <label class="font-size-h6 font-weight-bolder text-dark" for="form3Example4c">Contraseña</label>
                  <input
                    v-model="contrasena"
                    type="password"
                    required id="form3Example4c"
                    class="form-control form-control-solid h-auto py-6 px-6 rounded-lg"/>
                </div>
              </div>

              <div class="errMensaje">
                {{mensaje}}
              </div>

              <div id="resetPassword">
                  <a class="font-size-h6" href="javascript:void(0)" @click="resetPassword" >¿No recuerdas tu contraseña?</a>
              </div>

              <div class="d-flex justify-content-center">
                <button type="submit" class="btn btn-primary btn-lg">Iniciar sesión</button>
              </div>
            </form>
          <!--end::Signin-->
        </div>
        <div class="row justify-content-center">
          <div class="col col-lg-3">
            <hr style="margin-top: 30px">
          </div>
        </div>
        <div class="d-flex flex-center">
          <div class="d-flex flex-row align-items-center mb-4">
            <router-link to="/registro" style="text-decoration: none">
              <button class="btn btn-success btn-lg">Registrarse</button>
            </router-link>
          </div>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
//import VideoBg from "vue-videobg";

import {firebaseApp, db, storage} from "@/firebaseConfig";
firebaseApp;
db;
storage;
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
const auth = getAuth();
export default {
  name: "LoginVista.vue",
  components: {
    //VideoBg
  },
  data() {
    return {
      email: "",
      contrasena: "",
      error: "",
      mensaje:""
    }
  },
  methods: {
    login() {
      this.error = '';
      if (this.email && this.contrasena) {
        //envio formulario

        signInWithEmailAndPassword(auth, this.email, this.contrasena) //coge email y contraseña del formulario
            .then((userCredential) => { //si es correcto inicia sesion de usuario
              // Signed in
              this.email = ''; //formatea estos campos
              this.contrasena = '';
              this.mensaje = '';
              const user = userCredential.user; //el usuario del modelo pasa a ser el usuario que ha iniciado sesion
              this.$store.dispatch("firebase/fetchUser", user)
              this.$router.push({ name: 'Dashboard' }); //redirigimos al dashboard
            })
            .catch((error) => {
              this.mensaje ="";
              const errorCode = error.code;
              console.log(errorCode);
              if (errorCode === 'auth/wrong-password'){
                this.mensaje ="La contraseña indicada para este email no es correcta";
              }else if(errorCode === 'auth/user-not-found'){
                this.mensaje ="Este email no esta registrado en Dicopt";
              }
            });
      }else{
        this.mensaje = "Lo siento, no se ha podido iniciar sesión";
      }
    },
    resetPassword(){
      if (this.email !== ""){
        sendPasswordResetEmail(auth, this.email)
            .then(() => {
              this.mensaje = "Se ha enviado un email a la dirección de correo electrónico especificada (revisa el SPAM)";
              let textoMensaje = document.querySelector(".errMensaje");
              textoMensaje.style.color = "#339999";
            })
            .catch((error) => {
              const errorCode = error.code;
              console.log(errorCode)
              if (errorCode === 'auth/user-not-found'){
                this.mensaje ="El email no se encuentra en nuestra base de datos"
              }
              // ..
            });
      }else {
        this.mensaje = "Introduce el email de tu cuenta de Dicopt para continuar";
      }

    }
  },
}
</script>

<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";

hr {
  border: 0;
  clear:both;
  display:block;
  width: 96%;
  background-color:#BBBBBB;
  height: 1px;
}

h2 {
  margin-left: 20px;
  margin-bottom: 25px;
  font-size: 36px;
  color: rgba(12,21,114,0.56);
}
label{
  margin: 3px;
  font-weight: 600;
  color: rgba(20,28,112,0.43);
}

#resetPassword{
  text-align: right;
  margin-bottom: 15px;
}
#resetPassword a{
  font-weight: 600;
  color: rgba(20,28,112,0.43);
}
.errMensaje{
  width: 320px;
  color: red;
  margin-bottom: 5px;
}
</style>
