/*
import { createStore } from 'vuex'
import firebase from './firebase'

export default createStore({
    modules: {
        firebase
    },
})
*/

import Vue from 'vue'
import Vuex from 'vuex'
import firebase from './firebase'
import firestoreUser from './firestoreUser'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
      firebase,
      firestoreUser
  },
  state: {
    apiUrl: 'https://api.dicopt.com',
    isHeaderHidden: false,
    headerHeight: 0,
  },
  mutations: {
    setIsHeaderHidden(state, isHidden) {
      state.isHeaderHidden = isHidden;
    },
    setHeaderHeight(state, height) {
      state.headerHeight = height;
    },
  },
  actions: {
    updateHeaderState({ commit }, isHidden) {
      commit('setIsHeaderHidden', isHidden);
    }
  }
})
