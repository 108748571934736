<template>
  <section class="vh-100">
    <div class="container h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-lg-12 col-xl-11">
          <div class="card text-black" style="border-radius: 15px;">
            <div class="card-body p-md-5">
              <div class="row justify-content-center">
                <div class="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">

                  <h2>REGISTRO</h2>

                  <form class="mx-1 mx-md-4" @submit.prevent="registro">

                    <div class="d-flex flex-row align-items-center mb-4">
                      <i class="fas fa-user fa-lg me-3 fa-fw"></i>
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label" >Nombre y apellidos</label>
                        <input v-model="nombre" required type="text" class="form-control"/>
                      </div>
                    </div>

                    <div class="d-flex flex-row align-items-center mb-4">
                      <i class="fas fa-envelope fa-lg me-3 fa-fw"></i>
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label">Email</label>
                        <input v-model="email" required type="email" class="form-control"/>
                      </div>
                    </div>

                    <div class="d-flex flex-row align-items-center mb-4">
                      <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label">Contraseña</label>
                        <input v-model="contrasena" required type="password" class="form-control"/>
                      </div>
                    </div>

                    <div class="d-flex flex-row align-items-center mb-4">
                      <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label">Tipo de usuario</label>
                        <select v-model="type" required  class="form-control" id="registertype">
                          <option>Óptico optometrista</option>
                          <option>Oftalmólogo</option>
                        </select>
                      </div>
                    </div>

                    <div class="d-flex flex-row align-items-center mb-4">
                      <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label">Centro óptico / Hospital</label>
                        <input v-model="center" type="text"  class="form-control"/>
                      </div>
                    </div>

                    <div class="d-flex flex-row align-items-center mb-4">
                      <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label">Código postal</label>
                        <input v-model="postalCode" type="text" maxlength="5" minlength="5" class="form-control"/>
                      </div>
                    </div>

                    <div class="d-flex flex-row align-items-center mb-4">
                      <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label">Teléfono (opcional)</label>
                        <input v-model="phone" type="text" class="form-control"/>
                      </div>
                    </div>

                    <div class="d-flex flex-row align-items-center mb-4">
                      <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                      <div class="form-outline flex-fill mb-0">
                        <label class="form-label">Código de distribuidor/referido (si se dispone)</label>
                        <input v-model="distributorCode" type="text" class="form-control"/>
                      </div>
                    </div>


                    <div class="form-check d-flex justify-content-center mb-4">
                      <label class="form-check-label">
                        <input type="checkbox" required id="check"/>
                        <a href="https://dicopt.com/condiciones-generales-de-la-subscripcion/">
                          He leído y acepto los términos y condiciones de uso
                        </a>
                      </label>
                    </div>

                    <div class="errMensaje">
                      {{mensaje}}
                    </div>

                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                      <button type="submit" class="btn btn-primary btn-lg">Registrarme</button>
                    </div>

                  </form>

                </div>
                <div class="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">
                  <img src="media/qr/portada-plataforma.png"
                       class="img-fluid" alt="Logo V-Vision">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


</template>

<script>
import { app } from "@/firebaseConfig";
import { getFirestore } from "firebase/firestore";
import { doc,  setDoc} from "firebase/firestore";

import { getAuth, createUserWithEmailAndPassword, updateProfile  } from "firebase/auth";

const db = getFirestore(app);

export default {
  data() {
    return {
      id:"",
      nombre: "",
      email: "",
      contrasena: "",
      type: "",
      center: "",
      postalCode: "",
      mensaje: "",
      phone: "",
      distributorCode: "",
    }
  },
  name: "RegistroVista.vue",
  methods: {

    registro() {
      this.mensaje = '';
      if (this.nombre && this.email && this.contrasena && this.type) { //estos los campos son obligatorios
        //envio formulario
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, this.email, this.contrasena) //crea usuario con email y contraseña
            .then((userCredential) => {
              const user = userCredential.user; //el user pasa a ser el que ha creado
              updateProfile(auth.currentUser, {
                displayName: this.nombre

              }).then(() => {
                this.$store.dispatch("firebase/fetchUser", user);
                this.id = user.uid;
                this.insercionBD();
                this.$router.push({ name: 'Dashboard' })
                }).catch((err => {
                  this.error = err.code
                  console.log(this.error);
                  this.mensaje = "Error al listar en la base de datos";
                }))
              }).catch((err => {
              this.error = err.code;
              console.log(err.code)
                if (this.error === "auth/email-already-in-use") {
                  this.mensaje = "Este email ya esta registrado";
                }else if (this.error === "auth/weak-password"){
                  this.mensaje = "La contraseña tiene que ser al menos de 6 caracteres"
                }

              }))
      }else {
        this.mensaje="Debes rellenar todos los campos para continuar";
      }
    },
    insercionBD(){
        //para insertar en la bd
      let register = { //lo que voy a introducir en la bd

        name: this.nombre,
        email: this.email,
        type: this.type,
        center: this.center,
        postalCode: this.postalCode,
        phone: this.phone,
        distributorCode: this.distributorCode,
      }

        setDoc(doc(db, "usuarios", this.id), register) //id de inserccion es this.nombre, tengo que poner el id de auth
        .then(() => {
        console.log("Usuario guardado en la colección de Firebase Store");
        }).catch((err => {
        this.error = err.message
        alert(this.error)
        }))
    },
  }
}



</script>

<style scoped>
section {
  margin-top: 20px;
}

h2 {
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 40px;
  color: rgba(12,21,114,0.56);
}
label{
  margin: 2px;
  font-weight: 600;
  color: rgba(20,28,112,0.43);
}

.form-check-label{
  display: inline-block;
  position: relative;
  right: 25px;
  color: rgba(12,21,114,0.56);
  font-weight: 600;
}

.img-fluid{
  width: 600px;
  border-radius: 15px;
  margin-bottom: 90px;
}
#registertype{
  padding: 5px;
}
.errMensaje{
  color: red;
  margin-bottom: 15px;
}
</style>
