<template>
  <router-view/>
</template>


<script>
export default {
}
</script>


<style lang="scss">
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "assets/sass/style.vue";
</style>
