//uso el main solo para crear la instancia de la app de vue y el router
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"
import { auth } from "@/firebaseConfig"
import BootstrapVue from 'bootstrap-vue';
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import { InlineSvgPlugin } from "vue-inline-svg";
import VueApexCharts from 'vue-apexcharts'
import VueHtml2pdf from 'vue-html2pdf'
import ToggleButton from 'vue-js-toggle-button'

Vue.use(ToggleButton)
Vue.use(VueHtml2pdf)
Vue.use(VueApexCharts)
Vue.use(InlineSvgPlugin);
Vue.use(BootstrapVue);
Vue.use(VueMoment, {
    moment,
})

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//createApp(App).use(router).mount('#app')
let app
auth.onAuthStateChanged(async user => {
  if (!app) {
      //wait to get user
      user = await auth.currentUser;
      user;

      app = new Vue({
        el: '#app',
        store,
        router,
        render: h => h(App)
      });
      store.dispatch("firebase/fetchUser", user)
  }
});
