import LoginVista from "@/views/LoginVista";
import RegistroVista from "@/views/RegistroVista";
import DashboardVista from "@/views/DashboardVista";
import { getAuth } from "firebase/auth"; //importamos el modelo de auth de firebase para proteger la ruta con user logueado
import app from "@/firebaseConfig";
import { getFirestore, doc, getDoc } from "firebase/firestore";
var db = getFirestore(app);

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      redirect: "/home",
      component: DashboardVista,
      meta: {
        autentificado: true
      },
      children: [
        {
          path: "/home",
          name: "home",
          component: () =>
            import("@/views/HomeView.vue")
        },
        {
          path: "/juegos",
          name: "juegos",
          component: () =>
            import("@/views/GamesView.vue")
        },
        {
          path: "/guia-especialistas",
          name: "guia-especialistas",
          component: () =>
            import("@/views/TherapyView.vue")
        },
        {
          path: "/suscripciones",
          name: "suscripciones",
          component: () =>
            import("@/views/LicenceView.vue")
        },
        {
          path: "/pacientes",
          name: "pacientes",
          component: () =>
            import("@/views/PatientView.vue")
        },
        {
          path: "/plataforma",
          name: "plataforma",
          component: () =>
            import("@/views/PlatformInfoView.vue")
        },
        {
          path: "/graficas",
          name: "graficas",
          component: () =>
            import("@/views/StatisticsInfoView.vue")
        },
        {
          path: "/faq-plataforma",
          name: "faq-plataforma",
          component: () =>
            import("@/views/FAQInfoView.vue")
        },
        {
          path: "/extra-doc",
          name: "extra-doc",
          component: () =>
            import("@/views/ExtraDocView.vue")
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginVista
    },
    {
      path: '/registro',
      name: 'Registro',
      component: RegistroVista
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  const auth = getAuth();
  const usuario = auth.currentUser;

  let autorizacion = to.matched.some(registro => registro.meta.autentificado); //pregunta a todas las rutas si alguna de ellas necesita autentificado

  if (autorizacion && !usuario) {
    next('Login');
  } else {
    if (to.path === '/suscripciones' || to.path === '/pacientes') {
      try {
        // Obtén los datos del usuario desde Firestore (supongamos que el campo que deseas verificar se llama "campoFirestore")
        if (usuario) {
          const userDoc = doc(db, 'usuarios', usuario.uid); // Asegúrate de cambiar 'users' al nombre de tu colección
          const userDocSnapshot = await getDoc(userDoc);
          const userData = userDocSnapshot.data();

          // Verifica si el campoFirestore es true
          if (userData && "paid_register" in userData && userData.paid_register) {
            next();
          } else {
            // Redirige a otra ruta si no se cumple la condición
            next('/home');
          }
        }
      } catch (error) {
        console.error(error);
        next('/error'); // Maneja errores aquí
      }
    } else {
      next();
    }
  }
})

export default router
