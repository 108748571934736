import { auth } from '@/firebaseConfig'

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    user: null,
    userdata: {}
  },
  mutations: {
    setLoggedIn: (state, value) => {
      state.loggedIn = value;
    },
    setUser: (state, user) => {
      state.user = user;
    },
    setUserData: (state, userdata) => {
      state.userdata = userdata;
    }
  },
  getters: {
    loggedIn: state => {
      return state.loggedIn;
    },
    user: state => {
      return state.user;
    },
    userdata: state => {
      return state.userdata;
    }
  },
  actions: {
    async register(context, { email, password, name}){
      const response = await auth.createUserWithEmailAndPassword(email, password)
      if (response) {
          context.commit('setUser', response.user)
          response.user.updateProfile({displayName: name})
      } else {
          throw new Error('Unable to register user')
      }
    },

    async logIn(context, { email, password }){
      const response = await auth.signInWithEmailAndPassword(email, password)
      if (response) {
          context.commit('setUser', response.user)
      } else {
          throw new Error('login failed')
      }
    },

    async logOut(context){
        await auth.signOut()
        context.commit('setUser', null)
    },

    // this function is for avoid user unused
    fetchUser(context, user) {
      context.commit("setLoggedIn", user !== null);
      context.commit("setUser", user);
    }
  }
};
