/* Normalmente se ponen las credenciales para conectar con firebase en el archivo main.js, pero prefiero
* mantenerlo en este archivo por separado y exportarlo */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  
  apiKey: "AIzaSyBoL4IFsL7CtdemiHGzFAEwrjOvDN0mX3I",
  authDomain: "dicopt-10c47.firebaseapp.com",
  databaseURL: "https://dicopt-10c47.firebaseio.com",
  projectId: "dicopt-10c47",
  storageBucket: "dicopt-10c47.appspot.com",
  messagingSenderId: "222483552984",
  appId: "1:222483552984:web:efaa8bc73ecc4caab595ad",
  measurementId: "G-71M4X2GVLV"
  
  /*
  apiKey: "AIzaSyBzYUoaOhXjxi4YEy6akjt6595bDvAmg_8",
  authDomain: "dicopt-test.firebaseapp.com",
  databaseURL: "https://dicopt-test.firebaseio.com",
  projectId: "dicopt-test",
  storageBucket: "dicopt-test.appspot.com",
  messagingSenderId: "364643707821",
  appId: "1:364643707821:web:3f78dd92c0f590876f8f24"
  */
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
//initialize firebase auth
const auth = getAuth()

export {app, analytics, auth}
